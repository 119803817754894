@import "bootstrap-reboot.scss";

$gray_text_color: #F3F5F8;
$xs: 'only screen and (max-width:767px)';
$sm: 'only screen and (min-width:768px)';

body {
  color: #333;
  margin: 0;
  font-size: 14px;
}

.container {
  max-width: 980px;
  margin: 0 auto;
  padding: 15px 15px 0;
}

h1 {
  text-align: center;
}

h2 {
  margin: 20px 0;
}

header {
  background: $gray_text_color;
  padding: 30px 0;
  margin: 0 0 30px;
}

section#basic {
  text-align: center;
  margin: 20px 0;

  #catch_copy {
    font-size: 20px;
  }
}

.rows {

  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  @media #{$xs} {
    flex-direction: column;
  }
  @media #{$sm} {
    flex-direction: row;
    section {
      width: 45%;
    }
  }
}

section#history, section#profile {
  table {
    tr {
      margin-bottom: 5px;
    }

    td {
      padding: 10px;
      margin-bottom: 5px;
    }

    td:first-child {
      width: 90px;
      text-align: center;
      background: $gray_text_color;
    }
  }
}

section#accounts {
  text-align: center;
  margin: 20px 0 0;
}

section#products {
  margin: 30px 0;

  a {
    font-weight: bold;
  }

  p {
    margin: 0 0 5px;
  }

  span {
    margin-left: 10px;
    font-size: 14px;
  }

  li {
    margin-bottom: 20px;
  }
}


section#dev {
  padding: 30px;
  text-align: center;
  color: #666;
  background: $gray_text_color;

  #icons {
    font-size: 100px;
    line-height: 1.3;
  }
}

footer {
  padding: 30px;
  background: #2a527f;
  color: white;
  text-align: center;
  font-size: 13px;
}
